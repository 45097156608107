import Null from "components/NullComponent";
import Introduction from "./Introduction";
import Abstract from "./Abstract";
import GettingTheData from "./GettingTheData";
import ExploringMyData from "./ExploringMyData";
import RegressionAnalysis from "./RegressionAnalysis";
import Conclusion from "./Conclusion";

export {
  Abstract,
  Introduction,
  GettingTheData,
  ExploringMyData,
  RegressionAnalysis,
  Conclusion,
};

// Gatsby requires a default export
// to be a component or string
export default Null;
